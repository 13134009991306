.contact{
  padding-top: 7rem;
}
.container-contact {
  display: flex;
  justify-content: center;
  background-color: #fff;
  width: 100%;
}
.container-elements-contact {
  width: 90%;
  padding-top: 2rem;
}

.banner-bg-contact {
  background: url(../../assets/images/contactBanner.jpg);
  width: 100%;
  height: 16rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.banner-contact-bgblack {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 16rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-title-contact {
  color: rgba(255, 255, 255, 0.8);
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}
.contact-info__data-position span:first-child {
  text-transform: uppercase;
  color: var(--color-gray);
  font-weight: 700;
}

.container-map-contact {
  width: 100%;
  height: 26rem;
  margin-top: 2rem;
}
.container-form-info-contact {
  display: flex;
  width: 90%;
  flex-direction: column;
}
.container-form-info\&form {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 3rem 0rem 3rem 0rem;
}
.container-form-titles{
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 2rem;
  width: 100%;
}
.container-form-titles>h5,.container-form-titles>h2{
  color: #606060;
  margin: 0;
  text-transform: uppercase;
}
.container-form-titles>h5{
  color: var(--color-aqua);
}
.contact-info__container svg{
  transition: 0.5s ease;
}
.contact-info__container:hover svg{
  color: var(--color-aqua);

}
/*-----------------------------------------------------------------*/
/*--------------------------*CONTACT INFO*---------------------------*/
/*-----------------------------------------------------------------*/

.contact-info {
  display: flex;
  width: 55%;
  flex-wrap: wrap;
  gap: 2rem;
}

.contact-info__container {
  display: flex;
  color: #9d9d9d;
  font-weight: bold;
  width: 40%;
  background: #eeeeee45;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.contact-info__data-position > a{
  color: #9d9d9d;
}

.contact-info__icon {
  font-size: 3.5rem;
  margin-right: 5px;
}

.contact-info__data-position {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}
.contact-info__data-position a {
  color: var(--color-gray);
  font-weight: 400;
  margin: 0px 0px 5px 0px;
}
.contact-info__data-position span {
  color: var(--color-gray);
  font-weight: 400;
  margin: 2px 0px;
}

/*-----------------------------------------------------------------*/
/*--------------------------*GOOGLE MAP*---------------------------*/
/*-----------------------------------------------------------------*/

.map-google {
  width: 100%;
  height: 26rem;
}

/*-----------------------------------------------------------------*/
/*--------------------------*CONTACT FORM*---------------------------*/
/*-----------------------------------------------------------------*/

.contact-form {
  width: 45%;
  padding: 2rem;
  background-color: #eeeeee45
}
div#form form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.contact-form-inputs-container{
gap: 1rem;
}
.contact-form-inputs-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


.contact-form form div {
  display: flex;
  width: 100%;
  outline: 0;
  box-sizing: border-box;
  justify-content: center;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  border: 0.5px solid #c7c7c7;
  font-size: 0.9rem;
  outline: 0;
  padding: 0.5rem 0.5rem;
  font-family: "Poppins", sans-serif;
  resize: none;
}

.form-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/*-----------------------------------------------------------------*/
/*-------------------------*MEDIA QUERIES*-------------------------*/
/*-----------------------------------------------------------------*/
/*-------------------------*1440*-------------------------*/
@media (max-width: 1440px) {
  .container-form-info-contact {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
  }

}
/*-------------------------*850*-------------------------*/
@media (max-width: 850px) {
  .container-form-info\&form,.contact-info{
    justify-content: center;
    align-items: center;
  }
  .form-button{
    margin: 2rem 0rem;
  }
  .container-form-titles>h2{
    font-size: 20px;
  }
  .container-form-titles{
    margin-top: 3rem;
  }
  .contact-form {
    width: 90%;
    height: 100%;
    padding: 1rem;
    margin-bottom: 2rem;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .container-elements-contact {
    width: 90%;
  }
  .contact-info {
    margin: 3rem 0rem;
  }
  .container-form-info\&form {
    flex-direction: column;
    gap: 2rem;
    padding: 0rem;
  }
  .contact-info__container{
    padding: 1rem 0.5rem;
  }
    .contact-info {
      margin: 2rem 0rem 0rem 0rem;
      width: 100%;
    }
}
/*-------------------------*600*-------------------------*/

@media (max-width: 600px) {
  .map-google {
    width: 100%;
    height: 30rem;
  }
  .container-map-contact {
    width: 100%;
    height: 30rem;
  }
  .container-form-info-contact {
    flex-direction: column;
    width: 100%;
  }
  .contact-form-inputs-container {
    flex-direction: column;
  }
  .contact-form textarea {
    width: 100%;
  }
}
