.container-nav {
  height: 7rem;
  position: fixed;
  width: 100%;
  z-index: 100;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  -webkit-box-shadow: 0px 2px 9px 0px rgba(133, 133, 133, 1);
  -moz-box-shadow: 0px 2px 9px 0px rgba(133, 133, 133, 1);
  box-shadow: 0px 2px 9px 0px rgba(133, 133, 133, 1);
  transition: all 0.5s ease;
  top: 0;
}
.container-nav-scroll {
  height: 5rem;
  position: fixed;
  width: 100%;
  z-index: 100;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  -webkit-box-shadow: 0px 2px 9px 0px rgba(133, 133, 133, 1);
  -moz-box-shadow: 0px 2px 9px 0px rgba(133, 133, 133, 1);
  box-shadow: 0px 2px 9px 0px rgba(133, 133, 133, 1);
  transition: all 0.5s ease;
  top: 0;
}
.container-nav-scroll .img-logo {
  width: 150px;
  transition: width 0.5s ease;
}
.container-elements-nav {
  display: flex;
  width: 70rem;
  justify-content: space-between;
  align-items: center;
}
.img-logo {
  width: 13rem;
  transition: 0.5s ease;
}
.container-titles-nav {
  display: flex;
  width: 40rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
}
.container-titles-nav a {
  color: var(--hard-grey);
  font-weight: 500;
  margin: 0;
}
.container-titles-nav p {
  color: var(--hard-grey);
  font-weight: 500;
  display: flex;
  align-items: center;
}

.container-titles-nav p:hover {
  cursor: pointer;
}
.hover-underline-animation {
  display: inline-block;
  position: relative;
  padding-bottom: 0.4rem;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #4eeaff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.simbol-scout {
  width: 40%;
  justify-self: center;
  align-self: center;
  margin-top: 3rem;
}
.icon-close {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 2rem;
  position: relative;
  left: 10px;
}
.container-titles-nav>a:last-child {
  font-weight: 700;
}
/*-----------------------------------------------------------------*/
/*----------------------------*DRAWER*-----------------------------*/
/*-----------------------------------------------------------------*/

.container-drawer-nav {
  display: none;
}
/*-----------------------------------------------------------------*/
/*-------------------------*MEDIA QUERIES*-------------------------*/
/*-----------------------------------------------------------------*/

/*-------------------------*1700*-------------------------*/
@media (min-width: 1700px) {
  .container-elements-nav {
    width: 110rem;
  }
}

/*-------------------------*600*-------------------------*/

@media (max-width: 850px) {
  .container-drawer-nav {
    display: flex;
  }
  .container-drawer-nav {
    display: block;
  }
  .container-nav {
    height: 7rem;
    position: fixed;
    width: 100%;
    z-index: 100;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 0px 2px 9px 0px rgba(133, 133, 133, 1);
    -moz-box-shadow: 0px 2px 9px 0px rgba(133, 133, 133, 1);
    box-shadow: 0px 2px 9px 0px rgba(133, 133, 133, 1);
    top: 0;
  }
  .container-elements-nav {
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
  }
  .img-logo {
    width: 12rem;
  }
  .container-titles-nav {
    display: none;
  }
  /*----------------------------*DRAWER*-----------------------------*/
  .div-container-drawer-header {
    display: flex;
  }
  .div-drawer {
    display: flex;
  }
  .div-container-menu-mobile {
    display: flex;
    flex-direction: column;
    text-align: start;
    padding: 1rem;
    width: 20rem;
    box-sizing: border-box;
  }
  .div-container-menu-mobile>a {
    color: #595959;
    text-decoration: none;
    font-weight: 600;
    padding: 1rem 0rem;
    border-bottom: solid 1px #606060;
  }
  .div-container-elements-home {
    margin-top: 7.5rem;
  }
}


/* MENU SCOUT */
.scout-menu{
  display: none;
}
span.hover-underline-animation.products-menu:hover .scout-menu {
  position: absolute;
  background: white;
  display: flex;
  flex-wrap: wrap;
  width: 500px;
  top: 24px;
  left: 0px;
  gap: 1rem;
  padding: 2rem 0rem 2rem 2rem;
  justify-content: flex-start;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  
}
span.hover-underline-animation.products-menu{
  color: #606060 ;
  font-weight: 500;
}
ul.scout-menu {
  padding: 0;
  list-style: none;
  margin: 0;
}
.scout-menu li {
  width: 48%;
  height: 25px;
  cursor: pointer;
}
.scout-menu li a{
  text-transform: uppercase;
  font-size: 14px;
  color: #606060 ;
  transition: all 0.3s; 
  font-weight: 400 ;
  display: flex;
  align-items: center;
  height: 100%;
}
.scout-menu li:hover a {
  color: var(--color-aqua);
}
.scout-menu li:last-child a {
  color: white;
  font-weight: 400 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.scout-menu li:last-child {
  text-align: center;
  background-color: #7575757d;
  width: 93%;
}

.contact-mobile-menu{
  margin-top: 2rem;
}
.contact-mobile-menu > div {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 13px;

}
ul.scout-menu-mobile {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  padding: 1.3rem 0rem 1rem 0rem;
  flex-direction: column;
  gap: 1rem;
}
ul.scout-menu-mobile>li>a:hover{
  color: var(--color-aqua);
}
ul.scout-menu-mobile>li {
  color: #606060;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 14px;
}
.products__title-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #606060;
}
.products__title-menu span{
  font-weight: 600;
  color: #595959;
  padding: 1rem 0rem;
}
li.menu-mobile-descarga {
  background: #eeee;
  text-align: center;
  padding: 0.5rem;
}
