.nosotros {
  padding-top: 7rem;
}
.container-nosotros {
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0rem 0rem 4rem 0rem;
}

.bg-nosotros {
  background-image: url(../../assets/images/bgNosotros.jpg);
  height: 24rem;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container-elements-nosotros {
  width: 90%;
}

.bg-black {
  height: 14rem;
  padding: 2rem;
  margin: 2rem;
  width: 85%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background:linear-gradient(135deg, rgb(0 0 0 / 47%), rgba(255, 255, 255, 0));
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(15px);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.4);
  margin: 0 auto;
}

.experience-title-nosotros {
  font-size: 1.5rem;
  font-weight: 400;
  color: white;
  text-transform: uppercase;
}
.experience-title-nosotros strong {
  font-family: 900;
}
.quines-title-nosotros {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
}
.text-nosotros {
  font-size: 1.2rem;
  color: #fff;
  width: 60rem;
}
.cotnainer-pq-serv-nosotros {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.container-serv-nosotros:hover {
  transform: scale(1.03);
}
.container-serv-nosotros {
  cursor: pointer;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  text-align: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: 0.5s ease ;
}

.img-serv-card {
  width: 8rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  filter: invert(36%) sepia(3%) saturate(3%) hue-rotate(37deg) brightness(102%) contrast(93%);
}
.title-serv-card {
  margin-top: 0rem;
  font-size: 1.8rem;
  font-weight: 700;
  color: #606060;
}

/*-----------------------------------------------------------------*/
/*-------------------------*SLIDER*-------------------------*/
/*-----------------------------------------------------------------*/

.slider {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 3rem 0rem;
}
.slider-container {
  width: 92%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.aboutUs {
  width: 50%;
  z-index: 0;
}

.aboutUs-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}

.swiper {
  height: 100%;
}

.aboutUs img {
  display: block;
  width: 100%;
  height: 94%;
  cursor: pointer;
}

.slider-texto {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  line-height: 25px;
  height: 100%;
}

.slider-texto h1 {
  text-align: center;
  color: #606060;
  text-decoration: underline;
  line-height: 2;
  text-underline-offset: 10px;
  text-transform: uppercase;
}

.slider-texto p {
  color: var(--color-gray);
  text-align: start;
}
.slider-text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  /* padding: 0rem 3rem;
  height: 100%; */
  padding: 2.2rem 3rem;
  width: 65%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/* **PORQUE NOSOTROS** */
.bannerTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 0rem;
  width: 100%;
}
.bannerTitle__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85%;
}
.bannerTitle__container div {
  border: 1px solid black;
  width: 65%;
}

.bannerTitle span {
  text-align: center;
  width: 60%;
  font-size: 35px;
  color: var(--color-base);
  padding: 0rem 1rem;
}

.bannerTitle span b {
  text-align: center;
  width: 60%;
  font-size: 35px;
  color: var(--color-aqua);
  padding: 0rem 1rem;
}

/*-----------------------------------------------------------------*/
/*-------------------------*MEDIA QUERIES*-------------------------*/
/*-----------------------------------------------------------------*/

@media (min-width: 1700px) {
  .container-elements-nosotros {
    width: 91%;
    padding-bottom: 2rem;
  }

  .bg-nosotros {
    height: 25rem;
  }
  .container-serv-nosotros {
    width: 20%;
    height: 100%;
    -webkit-box-shadow: 0px 0px 10px 2px rgba(171, 171, 171, 1);
    -moz-box-shadow: 0px 0px 10px 2px rgba(171, 171, 171, 1);
    box-shadow: 0px 0px 10px 2px rgba(171, 171, 171, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    text-align: center;
  }
  .bg-black {
    width: 88%;
  }
  .text-nosotros {
    width: 90rem;
  }
  .quines-title-nosotros {
    font-size: 1.5rem;
    font-weight: 500;
    color: #fff;
  }
}
/*-------------------------*1325 slider*-------------------------*/

@media (max-width: 1325px) {
  .slider-texto {
    width: 40%;
  }
  .slider-texto h1{
    font-size: 21px;
  }
  .aboutUs {
    width: 32rem;
  }

  .slider-text-container {
    width: 100%;
  }
  .aboutUs-slide img {
    display: block;
    width: 32rem;
    height: 24rem;
  }
  .slider-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

/*-------------------------*950 slider*-------------------------*/

@media (max-width: 950px) {
  .slider{
    padding: 0rem;
    padding-bottom: 2rem;
  }
  .slider-container {
    flex-direction: column;
    padding-top: 1.2rem;
  }
  .slider-texto {
    width: 100%;
    margin-top: 2rem;
  }
  .aboutUs {
    width: 100%;
  }

  .aboutUs-slide img {
    display: block;
    width: 100%;
  }

  .slider-text-container {
    width: 100%;
  }
  .slider-text-container{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 2.2rem 1.5rem;
  }
  
}

/*-------------------------*600*-------------------------*/

@media (max-width: 850px) {
  .cotnainer-pq-serv-nosotros {
    margin-bottom: 4rem;
}
  .slider-text-container{
    padding: 1rem 1.5rem;
  }
  .container-nosotros {
    padding: 0rem 0rem;
  }
  .container-elements-nosotros {
    width: 22rem;
  }
  .bg-nosotros {
    height: 35rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .bg-black {
    padding: 2rem 0.5rem;
    height: 65%;
    width: 85%;
    margin: 0rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .quines-title-nosotros {
    font-size: 1.4rem;
    font-weight: 500;
    color: #fff;
  }
  .text-nosotros {
    font-size: 1.1rem;
    color: #fff;
  }
  .cotnainer-pq-serv-nosotros {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
  }
  .container-serv-nosotros {
    width: 85%;
    height: 100%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    text-align: center;
    margin-bottom: 2rem;
}
  .text-nosotros {
    font-size: 1.1rem;
    color: #fff;
    width: 95%;
  }

  .bannerTitle__container {
    width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .container-serv-nosotros:hover {
    transform: unset !important;
    transition: unset !important;
  }
  .bg-black:hover {
    transform: unset !important;
    transition: unset !important;
  }
  .aboutUs img {
    display: block;
    width: 100%;
    height: 350px;
  }
  .bannerTitle span b {
    text-align: center;
    font-size: 25px;
  }
  .bannerTitle__container {
    width: 100%;
  }
  .bannerTitle span {
    text-align: center;
    font-size: 25px;
    color: var(--color-base);
  }
}


/* 
NEW UPDATE */
.nosotros .swiper-button-prev:before,
.nosotros .swiper-button-next:before {
  content: '';
  background: #ffffff40;
  width: 45px;
  height: 45px;
  border-radius: 50px;
  z-index: -1;
  position: absolute;
}
.nosotros .swiper-fade .swiper-slide{
  height: max-content;
}