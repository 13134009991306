@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  background: white;
  font-family: "Montserrat", sans-serif;
  --swiper-theme-color: var(--color-aqua);
}
::marker {
  color: var(--color-aqua);
}

html {
  overflow-y: scroll;
}

:root {
  --font-base: "Poppins", sans-serif;
  --color-aqua: #39d5da;
  --color-gray: #606060;
  --color-background: #eeeeee;
  --hard-grey: #606060;

  /* //Swiper config */
  --swiper-navigation-color: white;
  --swiper-pagination-bullet-size: 15px;
  --swiper-pagination-color: var(--color-aqua);
  --swiper-pagination-bullet-inactive-color: var(--color-gray);
  --swiper-pagination-bullet-inactive-opacity: 0.7;
}

* {
  scroll-behavior: smooth;
}

a {
  color: unset;
  text-decoration: none;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #9ab4be;
}
::-webkit-scrollbar-thumb {
  background-color: #9ab4be;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  background-color: #9ab4be;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
