.service {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 7rem;
}

.service-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* .banner-bg-service {
  width: 100%;
  background: url(../../assets/prueba5.jpg) no-repeat center;
  background-size: cover;
  height: 320px;
} */

.banner-bg-service {
  background: url(../../assets/images/prueba1.jpg);
  width: 100%;
  height: 16rem;
  background-repeat: no-repeat;
  background-position: bottom;
  background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.banner-service-bgblack {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 16rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-title-service {
  color: rgba(255, 255, 255, 0.8);
  font-size: 4rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.service__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  padding: 2rem 0rem;
  margin: 1rem 0rem;
}

.service-container div:nth-child(3) {
  flex-direction: row-reverse;
}

.service__image {
  display: flex;
  align-items: center;
}

.service__image img {
  width: 200px;
}

.service__item-text {
  width: 60%;
}

.service__item-text p {
  color: gray;
  line-height: 1.6;
}

.service__item-text h1 {
  font-size: 30px;
  font-weight: 600;
  color: #606060;
  text-transform: uppercase;
}

.service-button {
  padding: 2rem 0rem 5rem 0rem;
}

@media (max-width: 600px) {
  .banner-bg-service {
    background: url(../../assets/images/prueba1.jpg);
    width: 100%;
    height: 23rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }
  .service__item {
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0rem;
    margin: 0rem;
  }

  .service-container div:nth-child(3) {
    flex-direction: column;
  }

  .service__item-text {
    width: 80%;
  }

  .service__image {
    margin-top: 2rem;
    width: 80%;
  }

  .service-button {
    width: 80%;
  }
}
