.footer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--color-background);
}

.footer__container {
  width: 89%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 230px;
}

.footer__contact div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.footer__icons {
  font-size: 30px;
  margin-right: 1rem;
  color: #606060;
}

.footer__contact,
.footer__logo,
.footer__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footer__info span,
.footer__contact span {
  font-size: 20px;
  color: #606060;
  font-weight: 600;
}

.footer__contact p {
  font-size: 14px;
  padding: 0.3rem 0rem;
}

.footer__info p {
  font-size: 14px;
  margin: 0rem;
  padding: 0.35rem 0rem;
}

.footer__logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 1rem;
  height: 200px;
}

.footer__logo img :nth-child(1) {
  color: var(--color-aqua);
  width: 100px;
}

.footer__info div {
  padding: 0.5rem 0rem;
  height: 0px;
  width: 150px;
  border-bottom: 2px solid #606060;
  position: relative;
    top: -5px;
}

.whatsapp-icon {
  width: 50px;
}

.footer__logo-responsive {
  display: none;
}

/* SUSCRIPCION */
.sp-link-wrapper.sp-brandname__left {
  display: none !important;
}
.sp-form-fields-wrapper.show-grid {
  font-family: "Montserrat", sans-serif !important;
}
label.sp-control-label {
  display: none !important;
}

.sp-form-fields-wrapper.show-grid {
  width: 1100px !important;
}
.sp-field.sp-button-container button {
  background: var(--color-aqua) !important;
}
form.sp-element-container.sp-field-nolabel div:first-child div p {
  font-weight: bold !important;
  font-size: 18px !important;
  color: #606060 !important;
}
.sp-form-fields-wrapper.show-grid {
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
}
form.sp-element-container.sp-field-nolabel div p {
  text-align: center !important;
}
.sp-field.sp-button-container {
  text-align: center !important;
}
.sp-form .sp-field {
  text-align: center !important;
}
@media screen and (min-width: 768px) {
  .sp-form-fields-wrapper.show-grid {
    height: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  form.sp-element-container.sp-field-nolabel div p {
    text-align: left !important;
  }
  .sp-field.sp-button-container {
    text-align: left !important;
  }
  .sp-form .sp-field {
    text-align: left !important;
  }
}

@media (max-width: 600px) {
  .footer__container {
    height: 370px;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem 0rem;
  }

  .footer__logo {
    display: none;
  }

  .footer__logo-responsive {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 2rem;
    height: 200px;
  }
  .footer__logo-responsive span {
    margin-top: 1.5rem;
  }

  .footer__contact,
  .footer__logo-responsive {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 85%;
  }

  .footer__info {
    display: none;
  }
}

.linkdin {
  position: relative;
  left: -15px;
  font-size: 30px;
  color: #606060;
}