.product__detail{
    margin-top: 7rem;
}
.product__detail-container{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem;

}
.product__detail-header {
    width: 40%;
    height: 385px;
}
.product__detail-header img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.porduct__detail-body{
    width: 75%;
    height: 100%
}
.product__detail-body-items {
    height: 100%;
}
.product__detail{
    gap: 2rem;
}
.product__detail-body-text > h1 {
    font-size: 20px;
    margin: 0;
    font-weight: 400;
    text-transform: uppercase;
}

.bannerImage-text{
    background: url('../../assets/images/backgroundProducts.jpg');
    height: 250px;
    width: 100%;
}
.bannerImage-text > h1{
    display: flex;
    align-items: center;
    margin: 0;
    text-align: center;
    justify-content: center;
    height: 100%;
    text-transform: uppercase;
    font-weight: 600;
    color: #606060;
    font-size: 35px;
    flex-direction: column-reverse;
    gap: 0.5rem;
}
.bannerImage-text > h1:after {
    content: 'SCOUT ELECTRONICA';
    font-size: 15px;
    font-weight: 200;
}
.product__detail-extraInfo{
    list-style: none;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;
    width: 75%;
        list-style: none;
        display: flex;
        gap: 2rem;
        flex-wrap: wrap;
        justify-content: center;
        width: 75%;
        margin: 0 auto;
        padding: 1rem 0rem 4rem 0rem;
    
}
.product__detail-extraInfo li{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 160px;
    text-align: center;
    background: #eeeeee;
    border-radius: 50%;
    height: 160px;
    gap: 0.5rem;
    transition: 0.5s ease ;
}
.product__detail-extraInfo li:hover{
    transform: scale(1.05);
}
ul.product__detail-extraInfo > li > img {
    width: 50px;
    height: 50px;
}
.product__detail-extraInfo li > span {
    width: 145px;
}


/* description */
ul.product__detail-body-items-description {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    margin-left: 3rem;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;;
    height: 100%;
    justify-content: center;
    padding: 1rem 2rem;
    height: 100%;
}

ul.product__detail-body-items-description > li {
    display: flex;
    text-align: start;
    padding: 0.5rem;
    line-height: 25px;
}


#product__detail .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet{
    width: 5px !important;
    height: 5px !important;
}

a.button__see-more {
    text-align: center;
    border: 1px solid #00000057;
    width: 30%;
    margin: 0 auto;
    padding: 0.5rem 0rem;
    margin-top: 1rem;
    transition: all 0.3s ease-in-out;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}
a.button__see-more:hover {
    border-color: var(--color-aqua);
    color: var(--color-aqua);

}


@media (max-width: 768px){
    .product__detail-container{
        width: 100%;
        flex-direction: column;
        padding: 0rem;
        justify-content: center;
    }
    .product__detail-header {
        width: 90%;
        height: 100%;
        margin-top: 2rem;
    }
    .porduct__detail-body {
        width: 90%;
        height: 100%;
        margin-top: 1rem;
    }
    ul.product__detail-body-items-description{
        margin-left: unset ;
        padding: 1rem;
    }
    a.button__see-more{
        width: 70%;
        margin-top: 2rem;
    }
    .product__detail-extraInfo{
        width: 100%;
        margin-top: 2rem;
    }
    .bannerImage-text{
        background-position: center;
        height: 150px;
    }
    .bannerImage-text > h1{
        display: flex;
        flex-direction: column-reverse;
        font-size: 25px;
    }
}