.swiper-button-next,
.swiper-button-prev {
  color:var(--color-aqua) !important;
}
.homeCard {
  display: flex;
  justify-content: center;
  padding-top: 7rem;
}
.homeCard__items {
  width: 100%;
  background-color: var(--color-background);
  padding: 5rem 3rem 7rem 3rem;
  display: flex;
  align-items: center;
}

.homeCard__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
}

.homeCard__title {
  text-align: center;
  font-weight: 500;
  font-size: 2.2rem;
  color: var(--hard-grey);
  margin-bottom: 3rem;
  text-transform: uppercase;
}

.homeCard__image {
  width: 800px;
  padding: 0rem 2rem;
}

.homeCard__image img {
  width: 100%;
}

.homeCard__button-responsive {
  display: none;
}

.home-clients {
  display: flex;
  align-items: center;
  justify-content: center;
}
span.homeCard__title {
  text-transform: uppercase;
}

.swiper-button-prev, .swiper-rtl .swiper-button-next{
  left: 20px !important;
}
.swiper-button-next, .swiper-rtl .swiper-button-prev{
  right: 20px !important;
}
.swiper-button-next, .swiper-button-prev{
  top: 55% !important;
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  content: 'prev';
  font-size: 20px !important;
}
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  content: 'next';
  font-size: 20px !important;
}
@media (max-width: 1700px) {
  .homeCard__items {
    background-color: var(--color-background);
    cursor: pointer;
  }
  .homeCard__title {
    color: var(--hard-grey);
    margin-bottom: 3rem;
    font-size: 1.7rem;
  }
}
@media (max-width: 850px) {
  .homeCard {
    padding-top: 8rem;
    height: 800px;
  }
  .homeCard__items {
    background-color: white;
    flex-direction: column;
    width: 100%;
    padding: 1rem 0rem;
  }

  .homeCard__container {
    width: 90%;
  }

  .homeCard__container span {
    font-size: 2rem;
    padding: 0rem;
    text-align: center;
  }

  .homeCard__container button {
    display: none;
  }

  .homeCard__image {
    width: 690px;
  }

  .homeCard__button-responsive {
    display: flex;
    cursor: pointer;
    margin-top: 3rem;
  }
  .homeCard__title {
    font-size: 1.3rem;
  }
}

@media (max-width: 600px) {
  .homeCard {
    height: 650px;
  }
  .homeCard__items {
    background-color: white;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
    margin-top: 3rem;
  }

  .homeCard__container {
    width: 100%;
  }

  .homeCard__container span {
    font-size: 1.5rem;
    padding: 0rem;
    height: 100px;
    overflow: hidden;
  }

  .homeCard__image {
    width: 95%;
  }

  .homeCard__button-responsive {
    display: flex;
    cursor: pointer;
    margin-top: 3rem;
  }
}


.home__banners {
  padding-bottom: 4rem ;
}