.clients {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
/* //BANER */
.clients-banner {
  background-color: var(--color-background);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 3rem;
}
.clients-banner span {
  font-size: 25px;
  align-items: center;
  color: #606060;
  font-weight: 600;
  text-transform: uppercase;
}
.clients-banner span strong {
  font-weight: 900;
}
.clients-banner-data {
  display: flex;
  width: 75%;
  padding: 3rem 0rem;
  justify-content: center;
}
.clients-banner-data div {
  width: 22%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.clients-banner-data div img {
  width: 60px;
  color: white;
  filter: invert(36%) sepia(3%) saturate(3%) hue-rotate(37deg) brightness(102%) contrast(93%);
}
.clients-banner-data div p {
  width: 90%;
}
.cotnainer-pq-serv-nosotros>div:first-child img {
  filter: none;
}
/* fin */

.clients-images {
  display: flex;
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  padding: 0rem;
  align-items: center;
  justify-items: center;
}

.clients-images img {
  width: 175px;
  cursor: pointer;
  padding: 2rem;
  transition: all 0.5s ease;
}

.clients-images img:hover {
  transform: none;
  -webkit-transform: scale(1.1) !important;
}

.clients-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0rem;
}

.clients-laurel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.clients-laurel img {
  width: 200px;
  z-index: 1;
}

/*-----------------------------------------------------------------*/
/*-------------------------*MEDIA QUERIES*-------------------------*/
/*-----------------------------------------------------------------*/

@media (min-width: 1700px) {
  .clients-images {
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 2rem 0rem;
  }
  .clients-images img {
    width: 250px;
  }

  .clients-laurel {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .clients-laurel img {
    width: 200px;
  }
}

@media (max-width: 850px) {
  .clients {
    width: 100%;
  }

  /* //BANER */
  .clients-banner {
    background-color: var(--color-background);
    width: 100%;
    padding: 1rem 0rem;
  }

  .clients-banner-data {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .clients-banner-data div p {
    width: 60%;
  }

  .container-title-clients {
    width: 13rem;
  }

  .clients-images {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: scroll;
    justify-content: flex-start;
  }
  .clients-images img {
    width: 300px;
  }

  .clients-laurel {
    display: none;
  }
  .clients-banner-data div{
    width: 50%;
  }
}

@media (max-width: 650px) {
  .clients-banner-data div p {
    width: 95%;
  }
  .clients-banner span {
    font-size: 25px;
    margin-top: 2rem;
    margin-bottom: 0rem;
    align-items: center;
  }
  .clients-banner-data div{
    width: 50%;
  }
}
